import React, { useRef } from 'react'
import { graphql } from 'gatsby'
import path from 'path'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Layout from 'components/Layout'
import Modal from 'components/Modal'
import Hero from 'components/Hero'
import OpenHours from 'components/OpenHours'
import OpeningTimes from 'components/OpeningTimes'
import { DangerLabel } from 'components/Typography'
import Contact from 'components/Contact'
import BrandInfo from 'components/BrandInfo'
import Categories from 'components/Categories'
import openingHoursUtils from 'utils/openingHours'
import { Container, Spacing50 } from 'styles/sharedStyle'
import theme from 'styles/theme'
import renderContent from 'utils/componentRenderer'
import constants from '../constants'

const DineDetailsPage = (props) => {
  const brandOpenTimesModalRef = useRef()

  const {
    data: {
      page,
      page: {
        openStatus,
        dineLogo,
        closingDownCopy,
        openingHours: brandOpeningHours,
        hero,
        categories,
        village,
        village: { openingHours: villageOpeningHours, villageSlug },
        content,
        jsonLdSchema,
      },
      openingHoursLabels,
      openingHoursExceptions,
      dineOpeningHoursExceptions,
      mapTranslations,
    },
    pageContext: { nodeLocale, pageLevel },
  } = props

  const isBrandOpen =
    openStatus === constants.OPEN_STATUS ||
    openStatus === constants.CLOSING_STATUS
  const isBrandClosing = openStatus === constants.CLOSING_STATUS

  const labels = (village && village.labels && village.labels[0]) || {}

  const globalLabels = openingHoursLabels.edges[0].node
  const villageExceptions = openingHoursExceptions.exceptions.reduce(
    openingHoursUtils.reduceExceptions,
    {}
  )
  const brandExceptions = dineOpeningHoursExceptions.exceptions.reduce(
    openingHoursUtils.reduceExceptions,
    {}
  )

  const media = hero && hero.media && hero.media[0]
  const hasHeroImage =
    (media && media.portrait && media.landscape) ||
    (media &&
      media.videoPlaceholder &&
      media.videoPlaceholder.landscape &&
      media.videoPlaceholder.portrait)
  const heroImage = hasHeroImage
    ? {
        portrait: media.portrait || media.videoPlaceholder.portrait,
        landscape: media.landscape || media.videoPlaceholder.landscape,
        altText:
          media.altText ||
          (media.videoPlaceholder ? media.videoPlaceholder.altText : ''),
      }
    : {}
  const hasHeroVideo = media && media.videoPortrait && media.videoLandscape
  const heroVideo = hasHeroVideo
    ? {
        portrait: media.videoPortrait,
        landscape: media.videoLandscape,
        opacity: media.opacity,
        name: media.name,
      }
    : {}
  const heroProps = hero
    ? {
        image:
          openStatus === constants.CLOSED_STATUS ||
          openStatus === constants.REOPENING_STATUS
            ? {}
            : heroImage,
        logo: dineLogo,
        video: heroVideo,
        villageSlug:
          pageLevel === 'collection'
            ? 'The Bicester Collection'
            : village?.name,
      }
    : null

  const brandHoursLabel = openingHoursUtils.getOpeningHours(
    villageOpeningHours,
    globalLabels,
    {
      brandOpeningHours,
      brandExceptions,
      villageExceptions,
    }
  )

  const brandHours = openingHoursUtils.getWeeklyOpeningHours(
    villageOpeningHours,
    globalLabels,
    {
      numberOfWeeks: 1,
      brandOpeningHours,
      brandExceptions,
      villageExceptions,
    }
  )

  const brandOpenHoursProps = {
    statusLabel: globalLabels.dineRibbonLabel,
    hoursLabel: brandHoursLabel,
    // @ts-ignore
    onClick: () => brandOpenTimesModalRef.current.openModal(),
  }

  const mapPageTypeSlug =
    (mapTranslations &&
      mapTranslations.edges &&
      mapTranslations.edges.length &&
      mapTranslations.edges[0].node.slug) ||
    'map'

  return (
    <Layout {...props}>
      <Helmet>
        {jsonLdSchema && jsonLdSchema.jsonLdSchema ? (
          <script type="application/ld+json">
            {jsonLdSchema.jsonLdSchema}
          </script>
        ) : null}
      </Helmet>
      {heroProps && <Hero {...heroProps} />}

      {isBrandOpen && (
        <>
          <OpenHours {...brandOpenHoursProps} />
          <Modal ref={brandOpenTimesModalRef}>
            {brandHours.map((hours, index) => (
              <OpeningTimes
                key={`week_${index.toString()}`}
                conditionsCopy={
                  brandOpeningHours &&
                  brandOpeningHours.footNoteCopy &&
                  brandOpeningHours.footNoteCopy.footNoteCopy
                }
                times={hours}
                title={globalLabels.dineHeading}
                isModal
              />
            ))}
          </Modal>
        </>
      )}

      <Spacing50 />

      {isBrandClosing && (
        <Container p={`${theme.space[11]} 0 0`} textAlign="center">
          <DangerLabel>{closingDownCopy}</DangerLabel>
        </Container>
      )}

      {isBrandOpen && (
        <BrandInfo
          brandCopy={page.dineCopy}
          readMoreCopy={
            page.dineReadMoreCopy &&
            page.dineReadMoreCopy.childMarkdownRemark.html
          }
          readLessLabel={labels.readLessLabel}
          readMoreLabel={labels.readMoreLabel}
        />
      )}

      {isBrandOpen && categories && <Categories items={categories} />}

      {renderContent(content, props)}

      <Contact
        email={page.emailAddress}
        emailLabel={labels.emailAddressLabel}
        phone={page.phoneNumber}
        phoneLabel={labels.phoneNumberLabel}
        title={labels.restaurantContactDetailsHeader}
        website={page.dineLink}
        websiteLabel={labels.onlineLabel}
        viewMapLabel={labels.viewOnMapLabel}
        viewMapUrl={
          page.leaseNumber &&
          path.join(
            villageSlug || '',
            nodeLocale,
            `${mapPageTypeSlug}#/profile?location=${page.leaseNumber}`
          )
        }
        facebook={page.facebookLink}
        facebookLabel={labels.facebookLabel}
        twitter={page.twitterLink}
        twitterLabel={labels.twitterLabel}
        pinterest={page.pinterestLink}
        pinterestLabel={labels.pinterestLabel}
        instagram={page.instagramLink}
        instagramLabel={labels.instagramLabel}
      />
    </Layout>
  )
}

DineDetailsPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pageContext: PropTypes.shape({
    nodeLocale: PropTypes.string.isRequired,
    pageLevel: PropTypes.string.isRequired,
  }).isRequired,
}

export const dineDetailsPageQuery = graphql`
  query(
    $id: String!
    $openingHoursId: String!
    $dineOpeningHoursId: String!
    #  $dateNow: Date!
    $dateStart: Date!
    $dateEnd: Date!
    $nodeLocale: String!
    $villageSlug: String
    $pageTypeSlug: String
    $needContactIcons: Boolean = false
  ) {
    page: contentfulPageTemplateRestaurantDetailT07(id: { eq: $id }) {
      jsonLdSchema {
        jsonLdSchema
      }
      id
      dineLink
      dineCopy
      dineReadMoreCopy {
        childMarkdownRemark {
          html
        }
      }
      phoneNumber
      emailAddress
      facebookLink
      twitterLink
      pinterestLink
      instagramLink
      leaseNumber
      openStatus: flags
      closingDownCopy
      categories {
        name
        label
      }
      dineLogo: logoImage {
        code {
          code
        }
      }
      hero {
        media {
          __typename
          ... on Node {
            ... on ContentfulCompVideoVid01 {
              ...responsiveVideoQuery
            }
            ... on ContentfulEntityImageEnt02 {
              ...ResponsiveImageQuery
            }
          }
        }
      }
      village {
        name
        villageSlug: slug
        villageId: contentful_id
        currency
        home: page_template_home_t01 {
          ...headerFooterVillage
          locale: node_locale
        }
        openingHours {
          ...villageOpeningHours
        }
        openingStatusLabel
        labels: label_brand_details_lab01 {
          readMoreLabel
          readLessLabel
          restaurantContactDetailsHeader
          facebookLabel
          twitterLabel
          pinterestLabel
          instagramLabel
          onlineLabel
          phoneNumberLabel
          viewOnMapLabel
          ...virtualShoppingIconsAndLabelsQuery
        }
        defaultLocale
      }
      content {
        __typename
        ... on Node {
          ...multipleComponentsT07
        }
      }
      openingHours {
        ...brandOpeningHours
      }
      pageTitle
      pageDescription
      hideFromSearchEngine
    }
    dineOpeningHoursExceptions: allContentfulCompOpeningHoursExceptionOph04(
      filter: {
        date: { lte: $dateEnd, gte: $dateStart }
        comp_opening_hours_boutique_oph02: {
          elemMatch: { id: { eq: $dineOpeningHoursId } }
        }
      }
    ) {
      exceptions: edges {
        node {
          date(formatString: "DD-MM-YYYY")
          openingTime
          closingTime
          closedFlag
          label
        }
      }
    }
    openingHoursExceptions: allContentfulCompOpeningHoursExceptionOph04(
      filter: {
        date: { lte: $dateEnd, gte: $dateStart }
        comp_opening_hours_village_oph01: {
          elemMatch: { id: { eq: $openingHoursId } }
        }
      }
    ) {
      exceptions: edges {
        node {
          ...contentfulOpeningHoursExceptions
        }
      }
    }
    openingHoursLabels: allContentfulLabelOpeningHoursLab04(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          ...contentfulOpeningHoursLabels
        }
      }
    }
    mapTranslations: allContentfulEntityPageTypeEnt06(
      filter: { instanceName: { eq: "map" }, node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          nodeLocale: node_locale
          slug
        }
      }
    }
    #    featuredOffers: allContentfulEntityOfferEnt05(
    #      filter: {
    #        node_locale: { eq: $nodeLocale }
    #        offerRunFinishDate: { gte: $dateNow }
    #      }
    #      sort: { fields: offerRunFinishDate }
    #    ) {
    #      nodes {
    #        id: contentful_id
    #        location {
    #          locationId: contentful_id
    #        }
    #        eyebrow
    #        headline
    #        description {
    #          description
    #        }
    #        offerDetailUrl
    #        offerExpirationLabel
    #        offerRunStartDate(formatString: "D MMMM")
    #        offerRunFinishDate(formatString: "D MMMM YYYY")
    #        offerImage {
    #          ...heroMediaQuery
    #        }
    #      }
    #    }
    labels: allContentfulLabelVillageLab09(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          visitBoutiqueLabel
        }
      }
    }
    memDaysOnboarding: contentfulCompMemDayOnb01(
      node_locale: { eq: $nodeLocale }
      village: { slug: { eq: $villageSlug } }
      pageTypes: { elemMatch: { slug: { eq: $pageTypeSlug } } }
    ) {
      ...memDaysOnboarding
    }
    allMemorableDays: allContentfulPageTemplateMemorableDaysT14(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
      }
    ) {
      edges {
        node {
          ...memDaysSlugs
        }
      }
    }
    allMembershipPopUps: allContentfulCompMembershipPopUp(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
        active: { eq: true }
      }
    ) {
      totalCount
      edges {
        node {
          ...membershipPopUp
        }
      }
    }
  }
`

export default DineDetailsPage
